import { template as template_9cb27ea843ab4e4192433a1233ae936c } from "@ember/template-compiler";
const FKText = template_9cb27ea843ab4e4192433a1233ae936c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
