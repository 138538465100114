import { template as template_d5f535e0cb064f1ea74013f0807b1ef4 } from "@ember/template-compiler";
const EmptyState = template_d5f535e0cb064f1ea74013f0807b1ef4(`
  <div class="empty-state-container">
    <div class="empty-state">
      <span data-test-title class="empty-state-title">{{@title}}</span>
      <div class="empty-state-body">
        <p data-test-body>{{@body}}</p>
      </div>
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default EmptyState;
