import { template as template_50a7670d8aaf4a78b2342ac29016e401 } from "@ember/template-compiler";
const FKLabel = template_50a7670d8aaf4a78b2342ac29016e401(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
